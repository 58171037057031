
.container{
    overflow-y: auto;
    height:70vh
}

.title{
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    background:linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 100px;
    padding:8px;
    padding-left: 28px;
    padding-right: 28px;
    display: inline-block;
    color:white;
}

.subtitle{
    font-size: 1.3rem;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 10px;
    font-weight: 500;
}

.paragraph{
    font-size: 1.1rem;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.activity{
    margin-top: 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid #DBDBDB;
}

.activityTime{
    display: inline-block;
    vertical-align: middle;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    background:#45009D;
    border-radius: 100px;
    padding:4px;
    padding-left: 32px;
    padding-right: 32px;
    color:white;
    margin-left: 20px;
    opacity:0.6;
}

.activityHighlights{
    margin-top:10px;
}

.highlight{
    display: inline-block;
    vertical-align: middle;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    background:#446BF7;
    border-radius: 100px;
    padding:4px;
    padding-left: 16px;
    padding-right: 16px;
    display: inline-block;
    color:white;
    margin-right: 10px;
}

@media all and (max-width: 700px) {

}