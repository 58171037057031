.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    flex-shrink: 0;
}

.columnWide{
  flex:2;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
  flex-shrink: 0;
}

.emoji{
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -2px;
    display: inline-block;
  }

  .documentsButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:300px;
  }
  .documentsButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: white;
    border-radius: 10px;
    color:#446BF7;
    border:2px solid #446BF7;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:296px;
  }
  .file:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileLabel{
    color:#446BF7;
    padding:10px;
  }
  
  .center{
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media all and (max-width: 700px) {
    .tiles{
        display: inline-block;
    }
  }