.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    flex-shrink: 0;
}

.emoji{
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -2px;
    display: inline-block;
}

.messages{
    background:#f5f2ff;
    width:100%;
    height:100%;
    position: relative;
}

.messagesWindow{
    width:700px;
    max-width: 90%;
    height:calc(100% - 10px);
    background:white;
    position: absolute;
    top:10px;
    left:50%;
    transform: translate(-50%,0%);
    display: flex;
    flex-direction: column;
    min-height: 0px;
}

.messagesHeader{
  padding-left: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
  display: flex;
}

.profileImage{
    border-radius: 200px;
    border:2px solid #181818;
    width:28px;
    height:28px;
    display: inline-block;
    vertical-align: middle;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;
}

.viewProfileContainer{
  flex:1;
  text-align: right;
  justify-content: flex-end;
}

.viewProfile{
  margin-right: 10px;
  padding:10px;
  border:1px solid #181818;
  font-weight: 500;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.viewProfile:hover{
  cursor:pointer;
  opacity:0.5;
}

.messagesContainer{
    flex:1;
    overflow-y: auto;
    min-height: 0px;
}

.inputContainer{
    text-align: center;
    border-top: 1px solid #DCDCDC;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.form{
    flex:1;
    display: flex;
    flex-direction: row;
}
  
.input{
    border-radius: 8px;
    border: 1px solid #b2bbd6;
    margin-right:20px;
    padding:10px;
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    flex:1;
}

.input:focus{
    outline: none;
    border: 1px solid #a2abc6;
}

.sendMessage{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 1.1rem;
  padding:8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  border:0px;
  color:white;
}

.sendMessage:hover{
    cursor: pointer;
    opacity: 0.8;
}

.sentMessageRow{
    padding:12px;
}

.sentMessage{
  align-self: flex-end;
  position: relative;
  left:calc(40% - 20px);
  background: #e0e8ff;
  padding:8px;
  border-radius: 4px;
  color:#0c1a42;
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
  width:60%;
  text-align:left;
}

.receivedMessageRow{
    padding:12px;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
    width:calc(100% - 24px);
}

.receivedMessage{
    background: #446BF7;
    padding:10px;
    border-radius: 6px;
    color:white;
    font-family: "Nunito", sans-serif;
    font-size: 1.1rem;
    width:60%;
    text-align:left;
}

.accountantMessages{
    background:#f5f2ff;
    width:100%;
    height:100%;
    position: relative;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    min-height: 0px;
}

.clientsListWindow{
  flex:2;
  height:calc(100% - 10px);
  background:white;
  margin-right: 20px;
}

.accountantMessagesWindow{
    flex:3;
    height:calc(100% - 10px);
    background:white;
    display: flex;
    flex-direction: column;
}

.client{
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #F1F1F1;
    padding-left: 20px;
    background-color: white;
}
.client:hover{
    cursor:pointer;
}

.clientProfileImage{
    border-radius: 200px;
    border:2px solid #181818;
    width:28px;
    height:28px;
    display: inline-block;
    vertical-align: middle;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;
}

.clientText{
    display: inline-block;
    vertical-align: middle;
  }

.clientName{
    font-size: 1rem;
}

.clientMessage{
    font-size: 0.9rem;
    color:#989898;
    margin-top: 5px;
}

.sentTimeLeft{
  padding-top: 4px;
  font-size: 0.9rem;
  color:#989898;
  margin-left:6px;
  text-align: left;
}

.sentTimeRight{
  padding-top: 4px;
  font-size: 0.9rem;
  color:#989898;
  text-align: right;
  margin-right:6px;
}


.seen{
    padding-top: 0px;
    font-size: 0.9rem;
    color:#989898;
    text-align: right;
    margin-right:6px;
    font-weight: 600;
  }

  .accountantMessagesWindowMobile{
    display:none;
  }

  .messagesTitle{
    font-size: 1.3rem;
    color:#181818;
    padding-top: 20px;
    padding-left: 20px;
    font-weight: 600;
  }

  
  @media all and (max-width: 700px) {
    .accountantMessagesWindow{
        display:none;
    }
    .accountantMessagesWindowMobile{
        z-index:10000;
        position:fixed;
        top:2%;
        height:95%;
        left:2%;
        width:95%;
        background: white;
        overflow-y: auto;
        border: 3px solid #2F00E9;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
      }
      .clientsListWindow{
        flex:2;
        height:calc(100% - 10px);
        background:white;
        margin-right: 20px;
        margin-left: 20px;
        border-radius: 20px;
      }

      .closeIcon{
        position: absolute;
        top:10px;
        right:16px;
        width:32px;
        background:white;
      }

      .messagesHeader{
        font-size: 0.9rem;
      }
      
  }
  
  