.error{
  font-weight: 600;
  text-align: center;
  color:#de0043
}

.content{
  text-align: center;
  align-items: center;
  justify-content: center;
}

.nieColumns{
  display: flex;
  flex-direction: row;
}

.nieColumn{
  flex:1;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.benefitsList{
  margin-right: 50px;
}

.checkIconSmall{
  width:36px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.td{
  padding-bottom: 28px;
  font-size: 1.1rem;
  margin-right: 5px;
  font-weight: 600;
}

.options{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.center{
  text-align: center;
}

.saveButton{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#353550,#353550);
  border-radius: 10px;
  color:white;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  align-items: center;
  text-align: center;
  display: inline-block;
  width:300px;
}
.saveButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.tile{
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.1vh;
  padding-bottom: 1.1vh;
  width:100%;
  box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  border-radius: 16px;
  min-height: 80vh;
  max-height:80vh;
  overflow-y: auto;
}

.titleIcon{
  width:26px;
  height:26px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  margin-right: 10px;
}

.title{
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 10px;
}

.tileSubtitle{
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.textInput{
  background:white;
  border-radius:10px;
  border: 1px solid #7C9ACF;
  font-size: 1.1rem;
  padding:30px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:300px;
  margin-top: 5vh;
  color:#989898;
  align-self: center;
}

.documents{
  margin-top: 20px;
}

.document{
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #EFEFEF;
  padding: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nomadDocument{
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #EFEFEF;
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #008080;
  //background:#446BF7;
  border-radius: 10px;
  color:white;
}



.documentsName{
  flex:1;
  padding-right: 40px;
}

.nomadDocumentsName{
  flex:1;
  padding-left: 10px;
  padding-right: 40px;
  margin-top: 4px;
  color:white;
}

.documentsButtonRow{
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  align-items: right;
  flex:1;
}

.documentsButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding-right:20px;
    padding-top:6px;
    padding-bottom: 6px;
    background: white;
    border-radius: 10px;
    border:0px solid #181818;
    color:#989898;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
  }
  .documentsButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  
  .nomadDocumentsButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    color: white;
    border-radius: 10px;
    background:linear-gradient(45deg,#446BF7,#45009D);
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
  }
  .nomadDocumentsButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .noSubmitButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:30px;
    padding-top:8px;
    padding-bottom: 8px;
    color: #989898;
    border-radius: 10px;
    background:#DBDBDB;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    align-self: flex-end;
    margin-top: 10px;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    margin-bottom: 20px;
  }

  .submitButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    color: white;
    border-radius: 10px;
    background:linear-gradient(45deg,#446BF7,#45009D);;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    align-self: flex-end;
    margin-top: 10px;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    margin-bottom: 20px;
  }
  .submitButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .submitButtonLarge{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.1rem;
    padding:40px;
    padding-top:10px;
    padding-bottom: 10px;
    color: white;
    border-radius: 10px;
    background:linear-gradient(45deg,#446BF7,#45009D);;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    display: inline-block;
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    margin-bottom: 20px;
  }
  .submitButtonLarge:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .noSubmitButtonLarge{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.1rem;
    padding:40px;
    padding-top:10px;
    padding-bottom: 10px;
    color: #989898;
    border-radius: 10px;
    background:#DBDBDB;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    align-self: flex-end;
    margin-top: 10px;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    margin-bottom: 20px;
  }

  .nomadVisaButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    color: #008080;
    border-radius: 10px;
    background:white;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
  }
  .nomadVisaButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .nomadDocumentsStartButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    background: #008080;
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 20px;
    display: inline-block;
  }
  .nomadDocumentsStartButton:hover{
    cursor:pointer;
    opacity:0.7;
  }


  .emoji{
    margin-left: 6px;
    margin-bottom: -3px;
  }

  .documentsSectionTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.2rem;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
  }

  .check{
    display: inline-block;
    vertical-align: middle;
    width:30px;
    height:30px;
    border-radius: 100px;
  }

  .chevron{
    margin-bottom: -3px;
    width:16px;
    height:16px;
  }

  .download{
    width:24px;
    height:24px;
    margin-right:10px;
    margin-top: 4px;
  }
  .download:hover{
    cursor:pointer;
  }


  .info{
    width:26px;
    height:26px;
    margin-top: 3px;
    margin-right: 10px;
  }
  .info:hover{
    cursor:pointer;
  }

  .submissionText{
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .submissionTextBold{
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
  }


  .checkIcon{
    width:70px;
    height:70px;
  }

  .loadingContainer{
    display: inline-block;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
  }

  .queriesButton{
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border-radius: 10px;
    color:#008080;
    border:1px solid #008080;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
  }
  .queriesButton:hover{
    cursor:pointer;
  }

  .query{
    margin-top: 30px;
    min-width: 18vw;
    max-width: 18vw;
    min-height: 100px;
    max-height: 100px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    border: 0px solid #446BF7;
    border-radius: 8px;
    padding:10px;
    font-family: "Montserrat", sans-serif;
  }

  @media all and (max-width: 700px) {
    .tile{
      padding:10px;
      font-family: "Montserrat", sans-serif;
      padding-top: 1.1vh;
      padding-bottom: 1.1vh;
      width:100%;
      box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
      border-radius: 16px;
      min-height: 70vh;
    }
    .info{
      margin-top: 0px;
      align-self: center;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .documentsButton{
      display:flex;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      flex:1;
      padding-bottom: 10px;
    }
  }