
.tile{
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.1vh;
  padding-bottom: 1.1vh;
  width:100%;
  box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  display: flex;
  flex-direction: row;
  border-radius: 16px;
}

.content{
  align-items: center;
  padding:10px;
  width:100%;
  justify-content: center;
  text-align: center;
}

.noAccountantTitle{
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

.noAccountantSubtitle{
  font-size: 1.1rem;
  text-align: center;
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.button{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  color:white;
  margin-top: 40px;
  align-self: center;
  align-items: center;
  text-align: center;
  position: relative;
  left:50%;
  transform: translate(-50%,0%);
}
.button:hover{
  cursor:pointer;
  opacity:0.7;
}

.chatbotButton{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: white;
  border-radius: 10px;
  color:#008080;
  border:1px solid #008080;
  margin-top: 20px;
  align-self: center;
  align-items: center;
  text-align: center;
  position: relative;
  left:50%;
  transform: translate(-50%,0%);
}
.chatbotButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.emoji{
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -1px;
  display: inline-block;
}

.profile{
  display: flex;
}

.profileImage{
  border-radius: 200px;
  border:3px solid #181818;
  width:32px;
  height:32px;
  object-fit: cover;
  object-position: center;
  align-self: center;
}

.profileText{
  flex:1;
}

.title{
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  text-align: center;
}

.name{
  font-size: 1rem;
  text-align: left;
  margin-left: 20px;
  margin-top: 5px;
}

.subtitle{
  font-size: 1.1rem;
  text-align: center;
  margin-top: 28px;
}