.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
}

.emoji{
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -2px;
    display: inline-block;
}

.button{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    background: #181818;
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .button:hover{
    cursor:pointer;
    opacity:0.7;
  }

.settingsContent{
  display: flex;
  flex-direction: row;
  height:calc(100%)
}

.settingsForm{
  padding-top: 6vh;
  flex:1;
  padding-left: 2vw;
  padding-right: 2vw;
  overflow-y: auto;
}

.settingsImage{
  flex:1;
  background-image: url('../../images/digital-nomad-3.jpg');
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;  
}

.submit{
  align-items: center;
  align-self: center;
  text-align: center;
}

.saveButton{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  color:white;
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  align-items: center;
  text-align: center;
  display: inline-block;
  width:300px;
}
.saveButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.question{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 20px;
}


@media all and (max-width: 700px) {
  .settingsImage{
    display: none;
  }
}