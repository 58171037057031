.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    flex-shrink: 0;
}

.emoji{
    vertical-align: middle;
    margin-left: 0px;
    margin-top: -4px;
    margin-right: 5px;
    display: inline-block;
}

.sections{
    padding-top: 40px;
    padding-left: 30px;
    overflow-y: auto;
}

.sectionTitle{
    bottom:10px;
    padding:10px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-size: 1.4rem;
    font-style: italic;
    color:#181818;
}

.sectionContent{
    height:280px;
    width:100%;
}

.sectionsList{
    margin-top: 10px;
}

.chosenSection{
    border:2px solid #446BF7;
    border-radius: 100px;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    color:white;
    background: #446BF7;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 1.1rem;
}
.chosenSection:hover{
    cursor:pointer;
}

.section{
    border:2px solid #446BF7;
    border-radius: 100px;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #446BF7;
    display: inline-block;
    vertical-align: middle;
    margin-right:10px;
    font-size: 1.1rem;
}
.section:hover{
    cursor:pointer;
}

.contentItem{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height:30vh;
    padding:10px;
    width:30vh;
    border-radius: 20px;
    margin-right: 20px;
    flex-shrink: 0;
    margin-top: 20px;
}
.contentItem:hover{
    cursor:pointer;
}
  
  .contentItemImage{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
  }
  
  .contentItemTitle{
    position: absolute;
    bottom:10px;
    padding:10px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.2rem;
    font-style: italic;
    color:white;
    text-shadow: 2px 2px 2px #181818;
  }
  
  .contentItemFooter{
    position: absolute;
    bottom:0px;
    left:0px;
    width:100%;
    height:45%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  @media all and (max-width: 700px) {
    .chosenSection{
        border:2px solid #446BF7;
        border-radius: 100px;
        padding:5px;
        padding-left: 10px;
        padding-right: 10px;
        color:white;
        background: #446BF7;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
    .chosenSection:hover{
        cursor:pointer;
    }
    
    .section{
        border:2px solid #446BF7;
        border-radius: 100px;
        padding:5px;
        padding-left: 10px;
        padding-right: 10px;
        color: #446BF7;
        display: inline-block;
        vertical-align: middle;
        margin-right:10px;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
    .section:hover{
        cursor:pointer;
    }
    .sectionsList{
        margin-top: 10px;
        align-items: center;
        text-align: center;
    }
    .contentItem{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        height:50vw;
        padding:10px;
        width:83vw;
        border-radius: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        margin-top: 20px;
    }
    .contentItem:hover{
        cursor:pointer;
    }
  }

  