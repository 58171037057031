.documentsWindowContainer{
  position: fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background:#484848CD;
  z-index:2000;
}

.documentsWidget{
    min-height: 90%;
    background:white;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    padding-bottom:40px;
}

.documentsWindow{
  position: absolute;
  top:50%;
  left:50%;
  width:50%;
  transform: translate(-50%,-50%);
  background:white;
  border-radius: 20px;
  overflow-y: auto;
  min-height: 40%;
  max-height: 95%;
  display: flex;
  flex-direction: column;
}

.documentsWindowTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
    padding: 24px;
}

.documentsWindowContent{
  padding:40px;
  font-size: 1.1rem;
  flex:1;
  overflow-y: auto;
  padding-bottom: 60px;
}

.closeIcon{
    position: fixed;
    top:16px;
    right:16px;
    width:32px;
    background:white;
}

@media all and (max-width: 700px) {
  .documentsWindowContainer{
    position: fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background:#484848CD;
    z-index:10000;
  }

  .documentsWindow{
    position: absolute;
    top:50%;
    left:50%;
    width:90%;
    transform: translate(-50%,-50%);
    background:white;
    border-radius: 20px;
    overflow-y: auto;
    min-height: 40%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    z-index:10000;
  }

}
