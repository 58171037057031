.button{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  color:white;
  margin-top: 0px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: center;
  align-items: center;
  text-align: center;
  display: inline-block;
  width:300px;
}
.button:hover{
  cursor:pointer;
  opacity:0.7;
}

.header{
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.1vh;
  padding-bottom: 1.1vh;
  width:calc(100% - 20px);
  box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  display: flex;
  flex-direction: row;
}

.headerWelcome{
  padding-left: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  vertical-align: middle;
  align-self: center;
}

.headerProfile{
  flex:1;
  padding-right: 30px;
  text-align: right;
}

.headerProfileImage{
  border-radius: 200px;
  border:3px solid #181818;
  width:36px;
  height:36px;
  object-fit: cover;
  object-position: center;
  display: inline-block;
  vertical-align: middle;
}

.menuIcon{
  display: none;
}

.emoji{
  vertical-align: middle;
  margin-right: 2px;
  margin-top: -2px;
  
} 

.headerWelcome{
  padding-left: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  vertical-align: middle;
  align-self: center;
}

.support{
    margin-right: 30px;
    font-size: 1rem;
    font-weight: 600;
    vertical-align: middle;
    align-self: center;
    display: inline-block;
}

.support:hover{
  cursor:pointer;
  opacity:0.5;
}

.center{
  text-align: center;
  align-items: center;
}

.supportInfo{
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.query{
  margin-top: 30px;
  min-width: 30vw;
  max-width: 30vw;
  min-height: 100px;
  max-height: 100px;
  border: 2px solid #446BF7;
  border-radius: 8px;
  padding:10px;
  font-family: "Montserrat", sans-serif;
}

.check{
  width:60px;
  margin-bottom: 30px;
}

.error{
  color:#ed0037;
  font-size: 1rem;
}

@media all and (max-width: 700px) {
  .headerProfileImage{
    display:none;
  }

  .support{
    display: none;
  }

  .menuIcon{
    width:36px;
    height:36px;
    object-fit: cover;
    object-position: center;
    display: inline-block;
    background:white;
  }

  .query{
    margin-top: 30px;
    min-width: 60vw;
    max-width: 60vw;
  }
}