.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
    background:white;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
}

.emoji{
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -2px;
    display: inline-block;
}

.button{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    background: #181818;
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .button:hover{
    cursor:pointer;
    opacity:0.7;
  }


.invoicesSubtitleContainer{
    padding-top: 40px;
    padding:30px;
    padding-right: 60px;
    display: flex;
}

.invoicesSubtitle{
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    flex:1;
    margin-right: 40px;
}

.invoicesTable{
    margin-top: 40px;
    margin:30px;
    margin-right: 60px;
    width:calc(100% - 90px);
    border-collapse: collapse; 
}

.invoicesTableHeader{
    font-weight: 500;
    color:white;
}

.invoicesTableHeaderItem{
  padding-left: 20px;
  padding-bottom: 14px;
  padding-top: 14px;
  font-size: 1.1rem;
  background:#446BF7;
}
.invoicesTableHeaderItemLeft{
padding-left: 20px;
  padding-bottom: 14px;
  padding-top: 14px;
  font-size: 1.1rem;
  background:#446BF7;
border-top-left-radius: 100px;
border-bottom-left-radius: 100px;
}
.invoicesTableHeaderItemRight{
padding-left: 20px;
  padding-bottom: 14px;
  padding-top: 14px;
  font-size: 1.1rem;
  background:#446BF7;
border-top-right-radius: 100px;
border-bottom-right-radius: 100px;
}

.invoicesTableRowItem{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f1efff;
}

.invoicesTableRow:hover{
    cursor:pointer;
    background-color: #f1efff;
}

.newInvoiceContent{
    justify-content: center;
    align-items: center;
    text-align: center;
}

.newInvoicesButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .newInvoicesButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

.invoicesButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:300px;
  }
  .invoicesButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: white;
    border-radius: 10px;
    color:#446BF7;
    border:2px solid #446BF7;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:296px;
  }
  .file:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileLabel{
    color:#446BF7;
    padding:10px;
  }

  .fullRow{
    display:flex;
    flex-direction: row;
  }

  .invoiceColumns{
    display: flex;
    flex-direction: row;
  }

  .invoiceColumn{
    flex:1;
  }

  .createInvoiceRowRight{
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 5px;
    flex:1;
  }

  .createInvoiceRowLeft{
    text-align: left;
    padding-top: 5px;
    flex:1;
  }

  .questionLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 10px;
    padding-left: 0px;
  }

  .questionRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .answerLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 10px;
    padding-left: 0px;
  }

  .answerRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .addressInput{
    padding:8px;
    min-width:300px;
    max-width:300px;
    min-height:150px;
    max-height:150px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1rem;
    margin-top: 10px;
    margin-right: 20px;
    white-space: pre-wrap;
  }
  .addressInput:focus{
    outline:none;
  }

  .checkbox{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width:20px;
    height:20px;
    border: 2px solid #181818;
  }

  .invoiceItemTextBox{
    padding:8px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1rem;
    margin-top: 10px;
    margin-right: 20px;
  }

  .invoiceItemsTable{
    width:100%;
    display: inline-block;
    overflow-y: hidden;
  }

  .addButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .addButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .removeButton{
    padding:20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 2px solid #ff2b56;
    color:#ff2b56;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius:100px;
    margin-top: 10px;
    text-align: center;
  }
  .removeButton:hover{
    cursor:pointer;
    opacity:0.6;
  }

  .textInput{
    padding:8px;
    width:300px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1rem;
    margin-top: 10px;
    margin-right: 20px;
  }

  .pdf{
    position: absolute;
    top:0px;
    left:0px;
    width:210mm;
    height:297mm;
    background:white;
  }

  .pdfFullRow{
    width:595.28pt;
  }

  .pdfInvoiceColumns{
    width:435pt;
    display:flex;
    flex-direction: row;
  }

  .pdfInvoiceColumn{
    flex:1;
    width:125pt;
  }


  .invoiceTitleLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
    padding-left: 5px;
  }

  .invoiceTitleRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 5px;
    width:445pt;
  }

  .invoicePdfTable{
    width:100%;
    overflow-y: hidden;
  }

  .pdfAnswerLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 5px;
  }

  .pdfAddressLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    padding-left: 5px;
  }

  .pdfAnswerRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .pdfCreateInvoiceRowLeft{
    text-align: left;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 5px;
    flex:1;
  }

  .pdfIntroRight{
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 5px;
    flex:1;
  }

  .pdfCreateInvoiceRowRight{
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 5px;
    flex:1;
    width:430pt;
  }

  .pdfQuestionLeft{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    padding-left: 0px;
  }

  .pdfQuestionRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .pdfDateRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .pdfInvoicePdfTable{
    margin-left: 5pt;
    overflow-y: hidden;
    border-collapse: collapse;
    border: 1px solid;
    padding-bottom:20pt;
  }


  .pdfAmountRight{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
  }


  .clients{
    height:calc(100% - 40px);
    padding:40px;
    overflow-y: auto;
  }

  .client{
    display: inline-block;
    width:160px;
    margin:20px;
    padding:20px;
    height:160px;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
    box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  }

  .client:hover{
    cursor: pointer;
  }

  .profileImage{
    border-radius: 200px;
    border:4px solid #181818;
    width:60px;
    height:60px;
    object-fit: cover;
    object-position: center;
    align-self: center;
  }

  .name{
    margin-top: 20px;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .documentsContent{
    position: relative;
  }

  .document{
    background:#f6f5ff;
    border-radius: 10px;
    padding: 10px;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .document:hover{
    cursor:pointer;
  }

  @media all and (max-width: 700px) {
    .invoicesTable{
      margin-top: 40px;
      margin:10px;
      margin-right: 10px;
      width:calc(100% - 20px);
      border-collapse: collapse; 
    }
    .newInvoicesButton{
      margin-top: 20px;
      align-self: center;
    }
    .invoicesSubtitleContainer{
      padding-top: 40px;
      padding:30px;
      padding-right: 30px;
      display: inline-block;
      text-align: center;
    }
    .invoicesSubtitle{
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      flex:1;
      margin-right: 0px;
    }
  }

  
