
.sideMenu{
  background:white;
  border-radius:0px;
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.4vh;
  padding-bottom: 1.4vh;
  width:280px;
  max-width: 20%;
  margin-top: 0vh;
  border:0px;
  border-right:1px solid #2F00E948;
  display: flex;
  flex-direction: column;
  height:calc(100% - 2.8vh);
  text-align: center;
  z-index:20;
}

.sideMenuLogo{
  width:30%;
  margin-top: 1vh;
  display: inline-block;
  position: relative;
  left:35%;
  margin-bottom: 2vh;
}

.sideMenuSeperator{
  height:1px;
  margin-top: 0vh;
  margin-bottom: 4vh;
  border-radius:10px;
  width:100%;
  background:#ebecf0;
}

.sideMenuWelcome{
  color:#282828;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  padding:0.4vw;
  padding-bottom: 1.8vh;
}

.sideMenuContent{
  width:100%;
  flex:1;
  overflow-y:scroll;
  text-align: left;
}

.sideMenuLogoutButton{
  width:100%;
  padding-top: 0.9vh;
  padding-bottom: 0.9vh;
  border: 2px solid #ff2b56;
  color:#ff2b56;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius:100px;
  margin-top: 1vh;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 4px;
}
.sideMenuLogoutButton:hover{
  cursor:pointer;
  opacity:0.6;
}

.sideMenuLogoutButtonIcon{
  width:1.1vw;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1vw;
  margin-left: 1vw;
}

.sideMenuLogoutButtonText{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
