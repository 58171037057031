
.formSubmitButton{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  color:white;
  margin-top: 40px;
  width:300px;
  align-self: center;
  align-items: center;
  text-align: center;
  position: relative;
  left:50%;
  transform: translate(-50%,0%);
}
.formSubmitButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.emoji{
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -1px;
  display: inline-block;
}

@media only screen and (max-width: 700px) {
  .formSubmitButton{
    width:200px;
    font-size: 1rem;
  }
}