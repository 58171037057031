.emoji{
  vertical-align: middle;
  margin-left: 2px;
  margin-top: -1px;
  
} 

#loginPanel{
    width:600px;
    max-width:80%;
    position: absolute;
    top:calc(50% + 4vh);
    left:50%;
    transform: translate(-50%, -50%);
    min-height:65%;
    text-align: center;
    align-items: center;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
    border-radius: 24px;
  }
  
  .loginLogoContainer{
    position: absolute;
    top:5vh;
    left:50%;
    transform: translate(-50%, 0%);
    text-align: center;
    align-items: center;
  }
  
  .loginLogo{
    height: 9vh;
    max-width: 70%;
    display: inline-block;
    vertical-align: middle;
  }
  
  
  .loginLogoText{
    display: inline-block;
    vertical-align: middle;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.6rem;
    font-style: italic;
  }

  .subtitle{
    margin-top: 10px;;
    display: inline-block;
    vertical-align: middle;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.3rem;
  }
  
  #loginHeaderMain{
    margin-left: 0px;
    margin-right: 0px;
    border-radius:0px;
    margin-top: 0vh;
    text-align: center;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
    color:white;
    font-size: 1.4rem;
    background:linear-gradient(45deg,#446BF7,#45009D);
    font-weight: 500;
    border-top:0px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    border-top-left-radius:24px;
    border-top-right-radius: 24px;
  }
  
  #loginLinks{
    font-size: 0.9rem;
    color:#5040e2;
    opacity:0.7;
    font-family: "Montserrat", sans-serif;
    margin-top: 3vh;
  }
  
  .loginLink{
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    text-decoration: none;
  }
  
  .loginError{
    font-size: 0.9rem;
    color:#d90f4f;
    opacity:0.7;
    font-family: "Comfortaa", cursive;
    margin-top: 3vh;
  }
  
  .loginCopyright{
    font-size: 0.8rem;
    color:#595959;
    opacity:0.7;
    font-family: "Comfortaa", cursive;
    position: absolute;
    bottom:20px;
    left:50%;
    transform: translate(-50%,0%);
  }
  
  @media only screen and (max-width: 768px) {
  
    .loginPanel{
      transform: translate(-50%, 0%);
      top:20vh;
      left:50%;
    }
  
  
  }