.screen{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
}

.content{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
}

.tiles{
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex:1;
    overflow-y: auto;
}

.column{
    flex:1;
    margin-left: 30px;
    margin-right: 30px;
}

.emoji{
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -2px;
    display: inline-block;
}

.button{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 0.9rem;
    padding:20px;
    padding-top:6px;
    padding-bottom: 6px;
    background: #181818;
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .button:hover{
    cursor:pointer;
    opacity:0.7;
  }


.documentsSubtitleContainer{
    padding-top: 40px;
    padding:30px;
    padding-right: 60px;
    display: flex;
}

.documentsSubtitle{
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    flex:1;
    margin-right: 40px;
}

.documentsTable{
    margin-top: 40px;
    margin:30px;
    margin-right: 60px;
    width:calc(100% - 90px);
    border-collapse: collapse; 
}

.documentsTableHeader{
    border-collapse: separate !important;
    font-weight: 500;
    color:white;
    border-radius: 100px;
    overflow: hidden;
    padding-left: 30px;
}

.documentsTableHeaderItem{
    padding-left: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    font-size: 1.1rem;
    background:#446BF7;
}
.documentsTableHeaderItemLeft{
  padding-left: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    font-size: 1.1rem;
    background:#446BF7;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.documentsTableHeaderItemRight{
  padding-left: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    font-size: 1.1rem;
    background:#446BF7;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.documentsTableRowItem{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f1efff;
}

.documentsTableRow:hover{
    cursor:pointer;
    background-color: #f1efff;
}

.newDocumentContent{
    justify-content: center;
    align-items: center;
    text-align: center;
}

.newDocumentsButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
  }
  .newDocumentsButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

.documentsButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:300px;
  }
  .documentsButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: white;
    border-radius: 10px;
    color:#446BF7;
    border:2px solid #446BF7;
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:296px;
  }
  .file:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .fileLabel{
    color:#446BF7;
    padding:10px;
  }

  .clients{
    height:calc(100% - 40px);
    padding:40px;
    overflow-y: auto;
  }

  .client{
    display: inline-block;
    width:160px;
    margin:20px;
    padding:20px;
    height:160px;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
    box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  }

  .client:hover{
    cursor: pointer;
  }

  .profileImage{
    border-radius: 200px;
    border:4px solid #181818;
    width:60px;
    height:60px;
    object-fit: cover;
    object-position: center;
    align-self: center;
  }

  .name{
    margin-top: 20px;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .document{
    background:#f6f5ff;
    border-radius: 10px;
    padding: 10px;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .document:hover{
    cursor:pointer;
  }

  @media all and (max-width: 700px) {
    .documentsTable{
      margin-top: 40px;
      margin:10px;
      margin-right: 10px;
      width:calc(100% - 20px);
      border-collapse: collapse; 
    }
    .newDocumentsButton{
      margin-top: 20px;
      align-self: center;
    }
    .documentsSubtitleContainer{
      padding-top: 40px;
      padding:30px;
      padding-right: 30px;
      display: inline-block;
      text-align: center;
    }
    .documentsSubtitle{
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      flex:1;
      margin-right: 0px;
    }
  }