.emoji{
  vertical-align: middle;
  margin-left: 2px;
  margin-top: -1px;
  
} 

#loginPanel{
    width:800px;
    max-width:80%;
    position: relative;
    left:50%;
    top:20vh;
    margin-bottom: 20vh;
    transform: translate(-50%, 0%);
    min-height:65%;
    text-align: center;
    align-items: center;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
    border-radius: 24px;
  }

  .appstore{
    width:30%;
  }

  .center{
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%)
  }
  
  .loginLogoContainer{
    position: absolute;
    top:5vh;
    left:50%;
    transform: translate(-50%, 0%);
  }
  
  .loginLogo{
    height: 9vh;
    max-width: 70%;
    display: inline-block;
    vertical-align: middle;
  }

  .affiliateText{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.1rem;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  
  .loginLogoText{
    display: inline-block;
    vertical-align: middle;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.6rem;
    font-style: italic;
  }
  
  #loginHeaderMain{
    margin-left: 0px;
    margin-right: 0px;
    border-radius:0px;
    margin-top: 0vh;
    text-align: center;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
    color:white;
    font-size: 1.4rem;
    background:linear-gradient(45deg,#446BF7,#45009D);
    font-weight: 500;
    border-top:0px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    border-top-left-radius:24px;
    border-top-right-radius: 24px;
  }
  
  #loginLinks{
    font-size: 1rem;
    color:#5040e2;
    opacity:0.7;
    font-family: "Montserrat", sans-serif;
    margin-top: 3vh;
    margin-bottom: 3vh;
    padding-bottom: 20px;
  }

  .note{
    font-family: "Comfortaa", cursive;
    color:#181818;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .loginLink{
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  .loginError{
    font-size: 0.9rem;
    color:#d90f4f;
    opacity:1;
    font-family: "Comfortaa", cursive;
    margin-top: 3vh;
  }
  
  .loginCopyright{
    font-size: 0.8rem;
    color:#595959;
    opacity:0.7;
    font-family: "Comfortaa", cursive;
  }

  .loginError{
    color:#d90f4f;
    font-size: 1.1rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .checkbox{
    width:16px;
    height:16px;
    vertical-align: middle;
    margin-top: -2px;
  }

  .checkboxText{
    font-size: 1rem;
    font-family: "Comfortaa", cursive;
  }

  
  @media only screen and (max-width: 700px) {
  
    #loginPanel{
      transform: translate(-50%, 0%);
      top:20vh;
      left:50%;
      max-width:95%;
      width:95%;
      position: absolute;
      min-height:65%;
      text-align: center;
      align-items: center;
      box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
      border-radius: 24px;
    }

    .loginLogoContainer{
      position: absolute;
      top:5vh;
      left:50%;
      transform: translate(-50%, 0%);
      text-align: center;
    }

    .loginLogo{
      height: 9vh;
      max-width: 70%;
      display: inline-block;
      vertical-align: middle;
      margin-right:0px;
    }  
  
  
  }