.container {
  position: fixed;
  bottom:10px;
  right:10px;
  z-index:5000;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.icon{
  width:40px;
  height:40px;
  border:2px solid white;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.5);
  border-radius: 100px;
}

.box{
  margin-bottom: 10px;
  border-radius: 10px;
  width:30vw;
  height:55vh;
  background: white;
  box-shadow: 0px 2px 18px rgba(10,0,180,0.3);
  flex-direction: column;
  display: flex;
  padding:5px;
}

.header{
  padding:10px;
  border-bottom: 1px solid #BCBCBC;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color:#181818;
  font-weight: 600;
  background:white;
}

.messages{
  flex:1;
  border-radius: 10px;
  width:100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.inputContainer{
  border-radius: 10px;
  padding:10px;
  text-align: center;
}

.input{
  margin:10px;
  border-radius: 8px;
  border: 1px solid #b2bbd6;
  width:80%;
  padding:10px;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
}

.input:focus{
  outline: none;
  border: 1px solid #a2abc6;
}

.chatbotMessageContainer{
  padding:12px;
}

.chatbotMessage{
  background: #446BF7;
  padding:10px;
  border-radius: 6px;
  color:white;
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
  width:60%;
  text-align:left;
}

.myMessageContainer{
  padding:12px;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  width:calc(100% - 24px);
}

.myMessage{
  align-self: flex-end;
  position: relative;
  left:calc(40% - 20px);
  background: #e0e8ff;
  padding:8px;
  border-radius: 4px;
  color:#0c1a42;
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
  width:60%;
  text-align:left;
}

.icon:hover{
  cursor: pointer;
  opacity: 0.8;
}

.emoji{
  vertical-align: middle;
  margin-left: 2px;
  margin-top: -2px;
} 

.assistantButton{
  padding:10px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  border-radius: 20px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  display: inline-block;
  color: white;
  font-weight:600;
  border:3px solid white;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.5);
}
.assistantButton:hover{
  cursor: pointer;
}

.close{
  position: absolute;
  top:10px;
  right:10px;
  width:30px;
  height:30px;
}

.loaderContainer{
  margin-top: 20px;
  margin-left: 12px;
  text-align: left;
}

@media all and (max-width: 700px) {
  .box{
    margin-bottom: 10px;
    border-radius: 10px;
    width:80vw;
    height:55vh;
    background: white;
    box-shadow: 0px 2px 18px rgba(10,0,180,0.3);
    flex-direction: column;
    display: flex;
    padding:5px;
  }
}
