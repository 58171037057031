
.sideMenu{
  background:white;
  border-radius:0px;
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.4vh;
  padding-bottom: 1.4vh;
  width:280px;
  max-width: 20%;
  margin-top: 0vh;
  border:0px;
  border-right:1px solid #2F00E948;
  display: flex;
  flex-direction: column;
  height:calc(100% - 2.8vh);
  text-align: center;
  z-index:20;
}

.sideMenuLogo{
  width:30%;
  margin-top: 1vh;
  display: inline-block;
  position: relative;
  left:35%;
  margin-bottom: 2vh;
}

.sideMenuSeperator{
  height:1px;
  margin-top: 0vh;
  margin-bottom: 4vh;
  border-radius:10px;
  width:100%;
  background:#ebecf0;
}

.sideMenuLogoText{
  color:#282828;
  font-weight: 600;
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
  padding:0.4vw;
  padding-top: 0px;
  padding-bottom: 3vh;
  font-style: italic;
}

.sideMenuContent{
  width:100%;
  flex:1;
  overflow-y:scroll;
  text-align: left;
}

.sideMenuLogoutButton{
  width:100%;
  padding-top: 0.9vh;
  padding-bottom: 0.9vh;
  border: 1px solid #ff2b56;
  color:#ff2b56;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  border-radius:10px;
  margin-top: 1vh;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 4px;
}
.sideMenuLogoutButton:hover{
  cursor:pointer;
  opacity:0.6;
}

.sideMenuLogoutButtonIcon{
  width:1.1vw;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1vw;
  margin-left: 1vw;
}

.sideMenuLogoutButtonText{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.mobileMenu{
  display: none;
}

@media all and (max-width: 700px) {
  .sideMenu{
    display:none;
  }
  .mobileMenu{
    display: inline-block;
    z-index:10000;
    position:fixed;
    top:2%;
    height:95%;
    left:2%;
    width:95%;
    background: white;
    overflow-y: auto;
    border: 2px solid #2F00E9;
    border-radius: 10px;
  }
  .sideMenuLogoutButton{
    position: relative;
    margin-top: 10vh;
    left:10%;
    width:80%;
  }
  .closeIcon{
    position: absolute;
    top:16px;
    right:16px;
    width:32px;
    background:white;
  }
  .sideMenuLogo{
    width:20%;
    margin-top: 1vh;
    display: inline-block;
    position: relative;
    left:40%;
    margin-bottom: 2vh;
  }
}
