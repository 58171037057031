.screen{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loader{
    position: relative;
    top:50%;
    transform: translate(0%,-50%);
}