
.tile{
  margin-top: 40px;
  padding:10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1.1vh;
  padding-bottom: 1.1vh;
  width:100%;
  box-shadow: 0px 3px 10px rgba(20, 0, 122, 0.1);
  border-radius: 16px;
  min-height: 40vh;
}

.titleIcon{
  width:26px;
  height:26px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  margin-right: 10px;
}

.title{
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 10px;
}

.tileSubtitle{
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.submit{
  align-items: center;
  align-self: center;
  text-align: center;
}

.saveButton{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.1rem;
  padding:12px;
  padding-bottom: 10px;
  background: linear-gradient(45deg,#446BF7,#45009D);
  border-radius: 10px;
  color:white;
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  align-items: center;
  text-align: center;
  display: inline-block;
  width:300px;
}
.saveButton:hover{
  cursor:pointer;
  opacity:0.7;
}