.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.documentInformationContent{
  display: flex;
  flex-direction: row;
}

.documentInformationText{
  flex:1;
  margin-right:20px;
}

.documentInformationTitle{
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 14px;
}

.documentInformationImage{
  width:36vh;
  height:36vh;
  border-radius:20px;
  object-fit: cover;
  object-position: center;
  border:4px solid #446BF7;
}

.radio{
  padding:10px;
  padding-top: 20px;
}

.radioOptions{
  align-items: center;
  text-align: center;
  justify-content: center;
  display:flex;
  margin-top: 10px;
  padding-left: 10px;
  padding-right:10px;
}

.radioOption{
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  border:1px solid #5603fc;
  color: #5603fc;
  background:white;
  font-weight: 500;
  border-radius: 6px;
  padding:10px;
  margin:10px;
  margin-left: 10px;
  margin-right: 10px;
  flex:1;
}

.radioOption:hover{
  cursor:pointer;
}

.radioOptionSelected{
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  border:1px solid #5603fc;
  background: #5603fc;
  color:white;
  font-weight: 500;
  border-radius: 6px;
  padding:10px;
  margin:10px;
  margin-left: 10px;
  margin-right: 10px;
  flex:1;
}

.radioOptionSelected:hover{
  cursor:pointer;
}


.radioQuestion{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
}

.rangeCurrency{
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  margin-right: 10px;
  margin-top: 10px;
}

.rangeInput{
  padding:8px;
  width:300px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 1px #9cb3ff;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  border:0px;
  font-size: 1.3rem;
  margin-top: 10px;
  margin-right: 20px;
}

.currencyInput{
  padding:8px;
  width:315px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 1px #9cb3ff;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  border:0px;
  font-size: 1rem;
  margin-top: 10px;
  margin-right: 20px;
}

.countryInput{
  width:320px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 1px #9cb3ff;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  border:0px;
  font-size: 1.3rem;
  margin-top: 20px;
  margin-right: 10px;
  align-self: center;
  position: relative;
  left:50%;
  transform: translate(-50%,0%);
}

.rangeInput:focus{
  outline:none;
}

.newInvoiceContent{
  flex:1;
  overflow-y: auto;
}

@media all and (max-width: 700px) {
  .documentInformationImage{
    display:none;
  }
}
